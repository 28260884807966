import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from "classnames";
import NumberFormat from 'react-number-format';
import { round, roundScaleDecimals } from "services/helpers"  


import {
  Form,
  FormGroup,
  Input as BootstrapInput,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
  Label,

} from "reactstrap";

import { Typography } from "stories/components"

import { NumericFormat, PatternFormat } from 'react-number-format';


import './Input.css';
import { de, te } from 'date-fns/locale';
import { set } from 'date-fns';

export const Input = ({ type="text",styles, className, icon, inputText, iconColor,addonTypeClass, label, labelInline=false, onWheel,  focused=false, errorText, multiple=false,required=false, children,setFieldValue, ...rest }) => {
  
  const [inputType, setInputType] = React.useState(type);

  let inputProps = {
    className: `custom_bootstrap_input ${className}`,
    type: inputType,
    onWheel: onWheel,
    ...rest,
  }


//---------------------number spaces-------------------------

const [value, setValue] = useState();  // This holds the actual number value
const [displayValue, setDisplayValue] = useState('');  // This holds the string to display
const [decimals, setDecimals] = useState(null);  // This holds the number of decimal places
const { onChange } = inputProps;
const [targett, setTargett] = useState();



const parseNumberString = (input1) => {
  // if input type is number then return input
  let input = input1;
  // const regex = /^[0-9\-−]+$/;
  const regex = /^[0-9\-−,. ]+$/;
  const isNumber  = regex.test(input);


  if (type === "number" && !isNumber) {
    //return input without latest character if it is not number/in regex
    input = input.slice(0, -1);
  }

  //check that if latest character is , or . and there is no number before it then add 0 before it
  if (input.endsWith(",") || input.endsWith(".")) {
    if (input.length === 1) {
      input = "0" + input;
    }
    else if (input.length === 2 && !isNumber) {
      //add 0 before , or . to second latest index
      input = input[0]+ "0"  + input[1];

      
    }
  }
  

  // Check if the input is a number in some reason.
  if (typeof input === "number") {
    if (input >= 0) {
      input = input.toString();
      // console.log("input",input)

    }
    else if (input < 0){
      // add negative sign
      input = "-"+input.toString();
      // console.log("input",input)
    }
  }

  // Remove any leading or trailing whitespace
  input = input.trim();

  if (input === '') {
    return "";
  }
  // Check if the input contains a negative sign and preserve it
  const hasNegativeSign = input[0] === '-' || input[0] === "−";
  

  let normalizedString = input
    .replace(/\s+/g, '')   // Remove all spaces
    .replace(/,/g, '.')    // Replace commas with dots
    .replace(/(\.\d*?)\./g, '$1'); // Handle multiple dots (keeps only the first dot)

  // Restore negative sign if it was originally present
  if (hasNegativeSign || input[0] === '-') {
    normalizedString = '-' + normalizedString.replace(/[^0-9.]/g, ''); // Allow digits and period, prefix with minus
  } else {
    normalizedString = normalizedString.replace(/[^0-9.]/g, ''); // Allow digits and period
  }


  // Convert to number
  const result = normalizedString;

  // Return the parsed number
  return isNaN(result) ? null : result;
}



const handleChange = (e) => {
  // console.log("handleChange", e);
  const inputValue = e.target.value;

  // Parse the input value as a 
  const normalizedString = parseNumberString(inputValue);

  const numericValue = parseFloat(normalizedString, "");
  // console.log("onChange", onChange);

  if (numericValue !== null || true ) {
    // Format the number with thousand separators (space as thousands separator)
    let formattedValue = numericValue
    if (numericValue !== null) {
      formattedValue = numericValue.toLocaleString('en', { 
        useGrouping: true,
        minimumFractionDigits: 0, // Adjust to your needs
        maximumFractionDigits: 20  // Adjust to your needs
      }).replace(/,/g, ' '); // Replace comma with space
    }

    if (inputValue.endsWith('.') || inputValue.endsWith(',')) {
      // If the input ends with a decimal separator, add it back
      formattedValue += '.';
    }

      
    //check if normalizedstring ends with zeros and add them back
    const [formattedIntegerPart, formattedDecimalPart] = formattedValue.split('.');
    const [integerPart, decimalPart] = normalizedString.split('.');
    // console.log("decimalPart", decimalPart)

    //if decimal part is not null and all are zeros
    let new_formattedValue = formattedValue

    if (decimalPart && decimalPart.length > 0) {
      // console.log("decimalPart added", decimalPart)
      setDecimals(decimalPart);
      new_formattedValue = formattedIntegerPart + "." + decimalPart;
    }
    else{
      setDecimals(null);
      // console.log("decimalPart not added", decimalPart)
    }

    if (new_formattedValue === "NaN") {
      // console.log("formattedValue is NaN", formattedValue)
      new_formattedValue = "";
    }

    // console.log("formattedValue", formattedValue)
    // console.log("new_formattedValue", new_formattedValue)

    setValue(numericValue);
    setDisplayValue(new_formattedValue);

    // Trigger the external onChange handler with the actual numeric value
    if (onChange) {
      const clonedEvent = {
        ...e,
        target: { ...e.target, value: numericValue, name: inputProps?.name, id: inputProps?.id }
      };
      setTargett(clonedEvent);
      // console.log(e.target);
      onChange(clonedEvent);
    }
  } else {
    // If not a valid number, update the display string only
    setDisplayValue(inputValue);
  }
};

//if inputprops value change then update display value
useEffect(() => {

  const regex = /^[0-9\-−]+$/;
  const isNumber  = regex.test(inputProps?.value);
  // console.log("isNumber", isNumber)
  if (type == "number"){

    if (inputProps.value) {
      const inputValue = inputProps?.value;
      const numericValue = parseFloat(inputValue);
      if (!isNaN(numericValue)) {
        // const formattedValue = numericValue.toLocaleString();
        let formattedValue = numericValue
        if (numericValue !== null) {
          formattedValue = numericValue.toLocaleString('en', { 
            useGrouping: true,
            minimumFractionDigits: 0, // Adjust to your needs
          maximumFractionDigits: 20  // Adjust to your needs
        }).replace(/,/g, ' '); // Replace comma with space
      }
      setValue(numericValue);

      if (decimals !== null) {
        //get formattedvalue integer and decimal part
        const [formattedIntegerPart, formattedDecimalPart] = formattedValue.split('.');
        setDisplayValue(formattedIntegerPart + "." + decimals);
      } else {
      setDisplayValue(formattedValue);
    }
    // setDisplayValue(formattedValue);
    } else {
      //check if inputValue is number using regex, or if it is empty or "-"
      if (isNumber || inputValue === "" || inputValue === "-" || inputValue ==="−") {
        setDisplayValue(inputValue);
      }
      else {
        setDisplayValue("");
      }


    }
  }

  }
}, [inputProps.value, decimals]);


const handleBlur = () => {
  // On blur, you might want to reset the display value to the numeric value or keep it as is
  // setDisplayValue(value.toString());  // Or you can keep the formatted string if preferred
};


//------------------------------END number spaces-----------------------------------------









  

  const setShowPassword = () => {
    if (inputProps.type === "password") {
      setInputType("text")
    } else {
      setInputType("password")
    }
  }
  
  //rerender if inputProps.type changes
  React.useEffect(() => {
    setInputType(inputProps.type);
  }, [inputProps.type]);


  if (type === "decimal") {
    inputProps = {
      ...inputProps,
      type: "text",
      pattern: "[0-9]+([.,][0-9]+)?"
    }

  }

  return (
    <div className={`d-flex ${labelInline ? "flex-row align-items-center" : "flex-column"}`}>
      {label && (
        <Typography bold className={`pr-1 `} style={styles?.label?styles?.label:{}}>{label} {required ? "*" : ""}</Typography>
      )}
      <InputGroup className="input-group-merge input-group-alternative">
        { type === 'email' ? (
          <InputGroupAddon addonType="prepend">
            <InputGroupText className={`${iconColor} ${inputProps.disabled? "input-group-text-custom": null}`} >
              <i className={icon?icon:"ni ni-email-83"} />
            </InputGroupText>
          </InputGroupAddon>
        ) : null }
        { type === 'password' ? (
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
              <i className={icon?icon:"ni ni-lock-circle-open"} />
              </InputGroupText> 
            </InputGroupAddon>
        ) : null }
        { type === 'text' ? ( 
          <>
            { icon && 
              <InputGroupAddon addonType="prepend">
                <InputGroupText className={`${iconColor} ${inputProps.disabled? "input-group-text-custom": null}`}>
                  <i className={icon?icon: null} />
                </InputGroupText> 
              </InputGroupAddon>
            }
          </>
        ) : null }
        { type === 'number' ? ( 
          <>
            { icon || (inputText && addonTypeClass =="prepend") &&
              <InputGroupAddon addonType={addonTypeClass}>
                <InputGroupText className={`${iconColor} ${inputProps.disabled? "input-group-text-custom form-control": null}`}>
                { icon && 
                  <i className={icon?icon: null} />
                }
                { inputText && inputText} 
                </InputGroupText> 
              </InputGroupAddon>
            }
          </>
        ) : null }

        
        { type === "select" ? (
          <>
            {icon && 
              <InputGroupAddon addonType="prepend">
                <InputGroupText className={`${iconColor} ${inputProps.disabled? "input-group-text-custom": null}`}>
                  <i className={icon} />
                </InputGroupText> 
              </InputGroupAddon>
            }
            <BootstrapInput
              {...inputProps}
              multiple={multiple}
            >
             {children}
            </BootstrapInput>
          </>
        ) : (
          
          <>
          {type ==="number" ? 
          <BootstrapInput
          {...inputProps}
            type="text"  // Type remains "text" to allow string display
            value={displayValue}  // Display the custom string
            onChange={handleChange}  // Handle input changes
            onBlur={handleBlur}  // Handle input blur event
          />


          
          // <NumericFormat  
          //   {...inputProps}   
          //   allowNegative={false}
          //   displayType={'input'}
          //   isNumericString={true}
          //   type={"text"}
          //   //text to transparent
          //   className={classnames("form-control", {
          //     "text-transparent": inputProps.disabled,

          //   })}

          //   customInput={BootstrapInput}  
          //   valueIsNumericString={true}
          //   thousandSeparator=" "
          //   // onChange={handleChange}
          //   onValueChange={(values) => {
          //     const { floatValue: rawValue } = values;
          //     // setValue(rawValue);
          //     // setFieldValue(inputProps.name, values.floatValue); 
          //     //set onChange function from props
          //     // onChange && onChange(values.floatValue);
          //   }}
          // />
          :
           <BootstrapInput
           {...inputProps}
           /> 
          }
          { (inputText && addonTypeClass == "append") &&
              <InputGroupAddon addonType={ `${addonTypeClass}` } style={{height: "39px"}}>
                <InputGroupText className={`ml-0 ${iconColor} ${inputProps.disabled? "input-group-text-custom form-control": null}`}>
                { icon && 
                  <i className={icon?icon: null} />
                }
                { inputText && inputText} 
                </InputGroupText> 
              </InputGroupAddon>
            }
          </>
          
        )}



        { inputProps.name.includes("password") ? (
          <InputGroupAddon addonType="append">
            <InputGroupText onClick={() => setShowPassword()}>
              {inputType === "password" ? (
                <i className="fas fa-eye-slash text-secondary" />
              ) : <i className="fas fa-eye text-secondary" />
              } 
     
            </InputGroupText>
          </InputGroupAddon>
        ) : null }




        
      </InputGroup>
      { errorText && (
        <Typography className="text-danger">{errorText}</Typography>
      )}
    </div>
  )
  
  
  
};

Input.propTypes = {
  errorText: PropTypes.string,
  focused: PropTypes.bool,
  label: PropTypes.string,
  labelInline: PropTypes.bool,
  required: PropTypes.bool,
  type: PropTypes.oneOf(['text','email','password','number','date','datetime-local','select','textarea', 'file']),
};

